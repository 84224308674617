import {OnInit, ViewEncapsulation} from '@angular/core';

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {UserService} from '../../../service/user.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {BaseComponent} from '../../../base/basecomponent';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [UserService]
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
    forgotPasswordForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(public router: Router,
                public dialog: MatDialog,
                private service: UserService,
                public loading: NgxSpinnerService,
                private _fuseConfigService: FuseConfigService,
                private _formBuilder: FormBuilder) {
        super(router, dialog, loading);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    onForgot(): void {
        const email = this.forgotPasswordForm.value.email;
        this.loading.show();
        this.service.forgot(email).subscribe(
            data => {
                this.loading.hide();
                this.openDialogAlert('Success', 'Enviado com sucesso');
                this.forgotPasswordForm.value.email = '';
            },
            error => {
                super.onError(error);
            });
    }
}
