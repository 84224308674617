import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class ReportSusepService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getSusep(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/susep/report`;
        return this.http.post(url, request);
    }

    getSusepPDF(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/susep/report/pdf/${request.month}/${request.year}`;
        return this.http.get(url);
    }

}
