import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base/basecomponent';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Controller} from '../../../model/proposition';
import {isNullOrUndefined, isUndefined} from 'util';
import {PolicyService} from '../../../service/policy.service';
import {Policy} from '../../../model/policy';
import {EnumService} from '../../../service/enum.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CoverageService} from '../../../service/coverage.service';

@Component({
    selector: 'app-policy-form',
    templateUrl: './policy-form.component.html',
    styleUrls: ['./policy-form.component.scss'],
    providers: [PolicyService, EnumService, CoverageService]
})
export class PolicyFormComponent extends BaseComponent implements OnInit {

    model: Policy = new Policy();
    modelController: Controller = new Controller();

    listController: Controller[] = [];

    listIncome: any = [];
    listActivity: any = [];
    listCoverage: any = [];

    displayedColumns = ['name', 'cpf', 'ppe', 'delete'];
    dataSource: any;

    displayedColumnsProfile = ['question', 'answer'];
    dataSourceProfile: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: PolicyService,
                public serviceEnum: EnumService,
                public serviceCoverage: CoverageService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['id'])) {
                this.model = new Policy();
            } else {
                this.getPolicy(p['id']);
            }
        });

        this.getListActivity();
        this.getListIncome();
        this.getListCoverage();
    }

    cancelPolicy(): void {
        this.router.navigate(['register-policy']);
    }

    validate(): boolean {

        if (isNullOrUndefined(this.model.emission)) {
            return true;
        }
        if (isNullOrUndefined(this.model.validity_start)) {
            return true;
        }
        if (isNullOrUndefined(this.model.validity_end)) {
            return true;
        }
        if (isNullOrUndefined(this.model.proposition_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.policy_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.cpf)) {
            return true;
        }
        if (isNullOrUndefined(this.model.taker)) {
            return true;
        }
        if (isNullOrUndefined(this.model.insured)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_zip_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_address)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_district)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_city)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_state)) {
            return true;
        }
        if (isNullOrUndefined(this.model.ddd)) {
            return true;
        }
        if (isNullOrUndefined(this.model.phone)) {
            return true;
        }
        if (isNullOrUndefined(this.model.email)) {
            return true;
        }
        if (isNullOrUndefined(this.model.birthday)) {
            return true;
        }
        if (isNullOrUndefined(this.model.gender)) {
            return true;
        }
        if (isNullOrUndefined(this.model.civil_status)) {
            return true;
        }
        if (isNullOrUndefined(this.model.vehicle)) {
            return true;
        }
        if (isNullOrUndefined(this.model.year)) {
            return true;
        }
        if (isNullOrUndefined(this.model.model_year)) {
            return true;
        }
        if (isNullOrUndefined(this.model.chassis)) {
            return true;
        }
        if (isNullOrUndefined(this.model.license_plate)) {
            return true;
        }
        if (isNullOrUndefined(this.model.color)) {
            return true;
        }
        if (isNullOrUndefined(this.model.email)) {
            return true;
        }
        if (isNullOrUndefined(this.model.zero_km)) {
            return true;
        }
        if (isNullOrUndefined(this.model.endorsement)) {
            return true;
        }
        if (isNullOrUndefined(this.model.endorsement_type)) {
            return true;
        }
        if (isNullOrUndefined(this.model.endorsement_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.premium)) {
            return true;
        }
        if (isNullOrUndefined(this.model.insurer)) {
            return true;
        }
        if (isNullOrUndefined(this.model.insurer_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.branch)) {
            return true;
        }
        if (isNullOrUndefined(this.model.branch_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.stipulating)) {
            return true;
        }
        if (isNullOrUndefined(this.model.importation_id)) {
            return true;
        }
        if (isNullOrUndefined(this.model.old_id)) {
            return true;
        }
        if (isNullOrUndefined(this.model.denial_date)) {
            return true;
        }
        if (isNullOrUndefined(this.model.mx2_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.blocked)) {
            return true;
        }
        if (isNullOrUndefined(this.model.ppe)) {
            return true;
        }
        if (isNullOrUndefined(this.model.activity_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.income_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.coverage_id)) {
            return true;
        }
        if (isNullOrUndefined(this.model.order)) {
            return true;
        }
        if (isNullOrUndefined(this.model.updated)) {
            return true;
        }
        if (isNullOrUndefined(this.model.ppex)) {
            return true;
        }
        return false;
    }

    getPolicy(id): void {
        this.loading.show();
        this.service.getPolicyId(id).subscribe(
            data => {
                this.model = data as Policy;
                this.listController = this.model.controllers;

                this.dataSource = new MatTableDataSource(this.listController);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

                this.dataSourceProfile = new MatTableDataSource(this.model.profiles);
                this.dataSourceProfile.paginator = this.paginator;
                this.dataSourceProfile.sort = this.sort;

                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    getListCoverage(): void {
        this.serviceCoverage.getCoverage('').subscribe(
            data => {
                this.listCoverage = data;
            },
            error => {
                super.onError(error);
            });
    }

    onSave(): void {
        if (!this.validate()) {
            console.log(this.model.id);
            if (isNullOrUndefined(this.model.id) || this.model.id === '') {
                this.newPolicy();
            } else {
                this.editPolicy();
            }
        }

    }

    newPolicy(): void {
        this.loading.show();
        this.model.controllers = this.listController;
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as Policy;
                this.loading.hide();
                this.router.navigate(['register-policy']);
            },
            error => {
                super.onError(error);
            });

    }

    editPolicy(): void {
        this.loading.show();
        this.model.controllers = this.listController;
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as Policy;
                this.loading.hide();
                this.router.navigate(['register-policy']);
            },
            error => {
                super.onError(error);
            });

    }

    addController(): void {
        this.listController.push(this.modelController);
        this.dataSource = new MatTableDataSource(this.listController);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.modelController = new Controller();
    }

    removeController(name): void {
        const index = this.listController.findIndex(a => a.name === name);
        this.listController.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.listController);
    }

    getListIncome(): void {
        this.serviceEnum.getIncome().subscribe(
            data => {
                this.listIncome = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListActivity(): void {
        this.serviceEnum.getActivity().subscribe(
            data => {
                this.listActivity = data;
            },
            error => {
                super.onError(error);
            });
    }

    delete(): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir essa Apólice?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.delete(this.model.id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Apólice deletada com sucesso');
                        this.router.navigate(['register-policy']);
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });
    }

}
