import {Branch, EndorsementType} from './policy';
import {Client} from './client';
import {Coverage} from './coverage';

export class ReportSusep {

    blocked: number;
    denial_date: string;
    endorsement_number: string;
    imp_segurada: string;
    insured: string;
    mx2_code: string;
    order: string;
    premium: number;
    proposition_number: string;
    stipulating: string;
    validity_start: string;

    id: string;
    insurer_id: string;
    coverage_id: string;
    endorsement_type_id: string;
    client_id: string;
    branch_id: string;
    branch: Branch;
    receipt_date: string;
    coverage: Coverage;
    endorsement_type: EndorsementType;
    client: Client;
    insurer: string;

    constructor() {
        this.endorsement_type = new EndorsementType();
        this.branch = new Branch();
        this.coverage = new Coverage();
        this.client = new Client();

    }

}
