import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {
    MAT_DATE_LOCALE,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseWidgetModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';
import {CoverageComponent} from './view/coverage/coverage.component';
import {PropositionComponent} from './view/proposition/proposition.component';
import {PolicyComponent} from './view/policy/policy.component';
import {NonStandartPolicyComponent} from './view/non-standart-policy/non-standart-policy.component';
import {DashboardComponent} from './view/dashboard/dashboard.component';
import {ReportIturanComponent} from './view/report-ituran/report-ituran.component';
import {ReportSusepComponent} from './view/report-susep/report-susep.component';
import {ReportRenovationComponent} from './view/report-renovation/report-renovation.component';
import {ClosureComponent} from './view/closure/closure.component';
import {CrmRegisterComponent} from './view/crm-register/crm-register.component';
import {CrmRaioxComponent} from './view/crm-raiox/crm-raiox.component';
import {CasualtyComponent} from './view/casualty/casualty.component';
import {AttendanceListComponent} from './view/attendance-list/attendance-list.component';
import {UserComponent} from './view/user/user.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {CoverageFormComponent} from './view/coverage/coverage-form/coverage-form.component';
import {NonStandartFormComponent} from './view/non-standart-policy/non-standart-form/non-standart-form.component';
import {PolicyFormComponent} from './view/policy/policy-form/policy-form.component';
import {PropositionFormComponent} from './view/proposition/proposition-form/proposition-form.component';
import {UserFormComponent} from './view/user/user-form/user-form.component';
import {CasualtyFormComponent} from './view/casualty/casualty-form/casualty-form.component';
import {ForgotPasswordComponent} from './view/login/forgot-password/forgot-password.component';
import {HeaderHttpInterceptor} from './base/header-http-interceptor';
import {CrmFormComponent} from './view/crm-register/crm-form/crm-form.component';
import {RaioxDetailComponent} from './view/crm-raiox/raiox-detail/raiox-detail.component';
import {LoadingComponent} from './view/dialog/loading/loading.component';
import {AlertComponent} from './view/dialog/alert/alert.component';
import {UserService} from './service/user.service';
import {MainDialogComponent} from './view/dialog/main-dialog/main-dialog.component';
import {NgxMaskModule} from 'ngx-mask';
import {ChartsModule} from 'ng2-charts';
import {NumberFormatterDirective} from './directive/number-formatter.directive';
import {DragDropDirective} from './directive/drag-drop.directive';
import {LoginComponent} from './view/login/login.component';
import {NgxCpfCnpjModule} from 'ngx-cpf-cnpj';
import {AttendanceDetailComponent} from './view/attendance-detail/attendance-detail.component';
import {AttendanceRegisterComponent} from './view/attendance-list/attendence-register/attendance-register.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ImportListComponent} from './view/import-list/import-list.component';
import {ImportRegisterComponent} from './view/import-list/import-register/import-register.component';
import {DialogComponent} from './dialog/dialog/dialog.component';
import { OutOfPatComponent } from './view/out-of-pat/out-of-pat.component';
import { EndOutOfPatComponent } from './view/end-out-of-pat/end-out-of-pat.component';
import { NewIcsComponent } from './view/new-ics/new-ics.component';
import { NewFormOutOfPatComponent } from './view/new-form-out-of-pat/new-form-out-of-pat.component';

const appRoutes: Routes = [
        {path: 'login', component: LoginComponent},
        {path: 'forgot-password', component: ForgotPasswordComponent},
        {path: '', component: DashboardComponent},
        {path: 'dashboard', component: DashboardComponent},
        {path: 'attendance-list', component: AttendanceListComponent},
        {path: 'attendance-list/:policy', component: AttendanceListComponent},
        {path: 'attendance-list-status/:status', component: AttendanceListComponent},
        {path: 'casualty', component: CasualtyComponent},
        {path: 'casualty-form/:hash', component: CasualtyFormComponent},
        {path: 'closure', component: ClosureComponent},
        {path: 'crm-raiox', component: CrmRaioxComponent},
        {path: 'raiox-detail/:hash', component: RaioxDetailComponent},
        {path: 'crm-register', component: CrmRegisterComponent},
        {path: 'crm-form/:hash', component: CrmFormComponent},
        {path: 'crm-form', component: CrmFormComponent},
        {path: 'import-list', component: ImportListComponent},
        {path: 'import-register', component: ImportRegisterComponent},
        {path: 'register-coverage', component: CoverageComponent},
        {path: 'coverage-form', component: CoverageFormComponent},
        {path: 'coverage-form/:hash', component: CoverageFormComponent},
        {path: 'register-non-standart-policy', component: NonStandartPolicyComponent},
        {path: 'non-standart-policy-form/:hash', component: NonStandartFormComponent},
        {path: 'non-standart-policy-form', component: NonStandartFormComponent},
        {path: 'register-policy', component: PolicyComponent},
        {path: 'register-policy/:coverage', component: PolicyComponent},
        {path: 'policy-form/:id', component: PolicyFormComponent},
        {path: 'policy-form', component: PolicyFormComponent},
        {path: 'register-proposition', component: PropositionComponent},
        {path: 'proposition-form/:hash', component: PropositionFormComponent},
        {path: 'proposition-form', component: PropositionFormComponent},
        {path: 'register-user', component: UserComponent},
        {path: 'user-form/:hash', component: UserFormComponent},
        {path: 'user-form', component: UserFormComponent},
        {path: 'report-ituran', component: ReportIturanComponent},
        {path: 'report-renovation', component: ReportRenovationComponent},
        {path: 'report-susep', component: ReportSusepComponent},
        {path: 'attendance-detail', component: AttendanceDetailComponent},
        {path: 'attendance-detail/:hash', component: AttendanceDetailComponent},
        {path: 'attendance-new/:cpf/:policy', component: AttendanceDetailComponent},
        {path: 'attendance-register', component: AttendanceRegisterComponent},
        {path: 'new-ics', component: NewIcsComponent},
        {path: 'end-out-of-pat', component: EndOutOfPatComponent},
        {path: 'out-of-pat', component: OutOfPatComponent},
        {path: 'new-form/:type/:id', component: NewFormOutOfPatComponent},
        {path: 'new-form/:type', component: NewFormOutOfPatComponent}
    ]
;


@NgModule({
    declarations: [
        AppComponent,
        CoverageComponent,
        PropositionComponent,
        PolicyComponent,
        NonStandartPolicyComponent,
        DashboardComponent,
        ReportIturanComponent,
        ReportSusepComponent,
        ReportRenovationComponent,
        ClosureComponent,
        CrmRegisterComponent,
        CrmRaioxComponent,
        CasualtyComponent,
        AttendanceListComponent,
        UserComponent,
        LoginComponent,
        ForgotPasswordComponent,
        CoverageFormComponent,
        NonStandartFormComponent,
        PolicyFormComponent,
        PropositionFormComponent,
        UserFormComponent,
        CasualtyFormComponent,
        CrmFormComponent,
        RaioxDetailComponent,
        LoadingComponent,
        AlertComponent,
        NumberFormatterDirective,
        DragDropDirective,
        MainDialogComponent,
        AttendanceDetailComponent,
        AttendanceRegisterComponent,
        ImportListComponent,
        DialogComponent,
        ImportRegisterComponent,
        OutOfPatComponent,
        EndOutOfPatComponent,
        NewIcsComponent,
        NewFormOutOfPatComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxSpinnerModule,
        RouterModule.forRoot(appRoutes),
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,

        FormsModule,
        ReactiveFormsModule,

        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatCardModule,
        MatToolbarModule,
        MatDividerModule,

        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatFormFieldModule,
        MatDialogModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatStepperModule,
        MatBadgeModule,
        FuseWidgetModule,
        NgxChartsModule,
        NgxCpfCnpjModule,
        ChartsModule
    ],
    entryComponents: [
        LoadingComponent,
        AlertComponent,
        MainDialogComponent
    ],
    providers: [
        // MessagingService,
        // AsyncPipe,
        // AuthGuard,
        // {
        //     provide: LOCALE_ID, useValue: 'pt-BR'
        // },
        {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {hasBackdrop: false}
        },
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        }],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
