import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base/basecomponent';
import {MatDialog} from '@angular/material';
import {Router} from '@angular/router';
import {ImportService} from '../../../service/import.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-import',
    templateUrl: './import-register.component.html',
    styleUrls: ['./import-register.component.scss'],
    providers: [ImportService]
})
export class ImportRegisterComponent extends BaseComponent implements OnInit {

    files: File[] = [];
    type = '';

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ImportService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
    }

    uploadFile(event): void {
        if (event.length > 50) {
            super.openDialogAlert('Error', 'O número máximo de arquivos a serem subidos por vez é 50!');
            return;
        }
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element);
        }
    }

    deleteAttachment(index): void {
        this.files.splice(index, 1);
    }

    sendFiles(): void {
        if (this.type === '') {
            this.openDialogAlert('Error', 'Selecione um tipo de arquivo!');
            return;
        }
        this.loading.show();
        this.service.sendFiles(this.files, this.type).subscribe(data => {
            this.openDialogAlert('Success', 'Upload de arquivos efetuado com sucesso');
            this.loading.hide();
            this.router.navigate(['import-list']);
        }, error => {
            super.onError(error);
        });
    }

}
