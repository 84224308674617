import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Proposition} from '../../model/proposition';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {PropositionService} from '../../service/proposition.service';
import {EnumService} from '../../service/enum.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-register-proposal',
    templateUrl: './proposition.component.html',
    styleUrls: ['./proposition.component.scss'],
    providers: [PropositionService, EnumService]
})
export class PropositionComponent extends BaseComponent implements OnInit {

    displayedColumns = ['status', 'proposition_number', 'policy_number',
        'movement_type', 'license_plate', 'cpf_cnpj', 'insured_name', 'insurer_name',
        'date', 'delete'];

    dataSource: any;
    model: Proposition = new Proposition();

    listStatus: any = [];

    request = {
        insured_name: '',
        cpf_cnpj: '',
        proposition_number: '',
        policy_number: '',
        license_plate: '',
        status: '',
        validity_start: '',
        validity_end: '',
        page: 1
    };

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: PropositionService,
                public serviceEnum: EnumService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
        this.getListStatus();
    }

    getList(): void {
        this.loading.show();
        this.service.getProposal(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Proposition[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.insured_name = '';
        this.request.cpf_cnpj = '';
        this.request.policy_number = '';
        this.request.license_plate = '';
        this.request.validity_start = '';
        this.request.validity_end = '';
        this.request.proposition_number = '';
        this.request.status = '';
        this.request.page = 1;

        this.getList();
    }

    getListStatus(): void {
        this.serviceEnum.getStatus().subscribe(
            data => {
                this.listStatus = data;
            },
            error => {
                super.onError(error);
            });
    }

    onEdit(id): void {
        this.router.navigate(['proposition-form/', id]);
    }

    newProposal(): void {
        this.router.navigate(['proposition-form']);
    }

    delete(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir essa proposta?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                    this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Proposta deletada com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
