import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {Attendance, Observation, Reason} from '../../model/attendance';
import {AttendanceService} from '../../service/attendance.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {User} from '../../model/user';
import {EnumService} from '../../service/enum.service';
import {UserService} from '../../service/user.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {Settings} from '../../settings';

@Component({
    selector: 'app-attendance-list',
    templateUrl: './attendance-list.component.html',
    styleUrls: ['./attendance-list.component.scss'],
    providers: [AttendanceService, UserService, EnumService, DatePipe],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AttendanceListComponent extends BaseComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    observationEdit: Observation;

    listUser: User[];
    listReason: Reason[];

    dataSource: MatTableDataSource<any>;
    columnsToDisplay = ['cpf', 'name', 'policy', 'license_plate',
        'created_at', 'closure', 'status', 'reason', 'user', 'delete'];
    expandedElement: Attendance | null;
    editElement: Attendance | null;

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public enumService: EnumService,
                public userService: UserService,
                public loading: NgxSpinnerService,
                private datePipe: DatePipe,
                public service: AttendanceService) {
        super(router, dialog, loading);
        this.observationEdit = new Observation();
        this.route.params.subscribe(p => {
            if (!isUndefined(p['policy'])) {
                this.request.policy = p['policy'];
            }

            if (!isUndefined(p['status'])) {
                this.request.status = p['status'];
            }
        });
    }

    request = {
        cpf: '',
        name: '',
        license_plate: '',
        policy: '',
        opening: '',
        closure: '',
        user_id: '',
        status: '',
        reason_id: '',
        email: '',
        phone: '',
        ddd: '',
        page: 1
    };

    ngOnInit(): void {
        this.getList();
        this.getReasonList();
        this.getUserList();
    }

    getReasonList(): void {
        this.enumService.getReason().subscribe(
            data => {
                this.listReason = data as Reason[];
            },
            error => {
                super.onError(error);
            }
        );
    }

    getUserList(): void {
        this.userService.list('{"page":1}').subscribe(
            data => {
                this.listUser = data as User[];
            },
            error => {
                super.onError(error);
            }
        );
    }

    getList(): void {
        this.loading.show();
        this.service.getAttendance(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Attendance[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.cpf = '';
        this.request.name = '';
        this.request.license_plate = '';
        this.request.policy = '';
        this.request.opening = '';
        this.request.closure = '';
        this.request.user_id = '';
        this.request.status = '';
        this.request.reason_id = '';
        this.request.page = 1;

        this.getList();
    }

    getExcel(): void {
        const token = localStorage.getItem('token') as String;
        let url = `${Settings.BASE_URL}/api/attendance/excel/?access_token=${token}`;
        url += ('&cpf=' + this.request.cpf);
        url += ('&name=' + this.request.name);
        url += ('&license_plate=' + this.request.license_plate);
        url += ('&policy=' + this.request.policy);
        url += ('&status=' + this.request.status);
        url += ('&reason_id=' + this.request.reason_id);
        url += ('&user_id=' + this.request.user_id);
        url += ('&created_at=' + this.request.opening);
        url += ('&closure=' + this.request.closure);
        window.open(url);
    }

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    delete(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir esse atendimento?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Atendimento deletado com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });

    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }
}
