import {Branch, EndorsementType} from './policy';
import {Controller} from './proposition';
import {Client} from './client';
import {Coverage} from './coverage';

export class WithoutPatternPolicy {

    id: string;
    policy_number: string;
    validity_start: string;
    validity_end: string;
    license_plate: string;
    insured: string;
    cpf_cnpj: string;
    broker: string;
    product: string;

    controllers: Controller[];
    emission: string;
    proposition_number: string;
    document_number: string;
    taker: string;
    cpf: string;
    zip_code: string;
    address: string;
    number: string;
    district: string;
    city: string;
    state: string;
    ddd: string;
    phone: string;
    email: string;
    birthday: string;
    gender: string;
    civil_status: string;
    vehicle: string;
    year: string;
    model_year: string;
    chassis: string;
    color: string;
    zero_km: string;
    endorsement: string;
    endorsement_number: number;
    premium: string;
    insurer_code: number;
    branch_number: string;
    stipulating: string;
    importation_id: number;
    old_id: number;
    denial_date: string;
    mx2_code: string;
    blocked: number;
    ppe: number;
    activity_code: string;
    income_code: string;
    updated: number;
    ppex: number;
    coverage_code: number;
    order: number;

    client_id: string;
    coverage_id: string;
    insurer_id: string;
    endorsement_type_id: string;
    branch_id: string;
    endorsement_type: EndorsementType;
    branch: Branch;
    insurer: string;
    coverage: Coverage;
    client: Client;

    constructor() {

    }

}
