import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Proposition} from '../../model/proposition';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {ReportIturanService} from '../../service/report-ituran.service';
import {ReportIturan} from '../../model/report-ituran';
import {NgxSpinnerService} from 'ngx-spinner';
import {Settings} from '../../settings';
import {Filters} from '../../base/filters';
import {Utils} from 'tslint';

@Component({
    selector: 'app-report-ituran',
    templateUrl: './report-ituran.component.html',
    styleUrls: ['./report-ituran.component.scss'],
    providers: [ReportIturanService]
})
export class ReportIturanComponent extends BaseComponent implements OnInit {

    displayedColumns = ['policy_number', 'proposition_number',
        'branch', 'vehicle', 'chassis', 'license_plate', 'coverage', 'endorsement', 'cpf', 'insured', 'validity_start', 'validity_end'];

    dataSource: any;
    model: Proposition = new Proposition();

    dropdownYear = Filters.createYearsList();

    request = {
        month: (new Date().getMonth() + 1).toString(),
        year: '2019',
        page: 1,
        emission: this.getDateNowNoFormat()
    };

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ReportIturanService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
    }

    getList(): void {
        this.loading.show();
        this.service.getIturan(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as ReportIturan[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    getExcel(): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/ituran/report/excel/${this.request.month}/${this.request.year}/?access_token=${token}`;
        window.open(url);
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

    getPDF(): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/ituran/report/pdf/${this.request.month}/${this.request.year}/${this.request.emission}/?access_token=${token}`;
        window.open(url);
    }

    resetFilter(): void {
        this.request.month = (new Date().getMonth() + 1).toString();
        this.request.year = '2019';
        this.request.page = 1;

        this.dataSource = [];
    }
}
