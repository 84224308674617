import {Component, OnInit} from '@angular/core';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {BaseComponent} from '../../../base/basecomponent';
import {ActivatedRoute, Router} from '@angular/router';
import {Casualty} from '../../../model/casualty';
import {CasualtyService} from '../../../service/casualty.service';
import {isUndefined} from 'util';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-casualty-form',
    templateUrl: './casualty-form.component.html',
    styleUrls: ['./casualty-form.component.scss'],
    providers: [CasualtyService]
})
export class CasualtyFormComponent extends BaseComponent implements OnInit {

    model = new Casualty();

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: CasualtyService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new Casualty();
            } else {
                this.getCasualty(p['hash']);
            }
        });
    }

    getCasualty(id): void {
        this.loading.show();
        this.service.getCasualtyID(id).subscribe(
            data => {
                this.model = data as Casualty;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    cancel(): void {
        this.router.navigate(['casualty']);
    }

}
