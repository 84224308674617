import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/basecomponent';
import {MatDialog} from '@angular/material';
import {isNullOrUndefined, isUndefined} from 'util';
import {ActivatedRoute, Router} from '@angular/router';
import {Client} from '../../../model/client';
import {ClientService} from '../../../service/client.service';
import {Raiox} from '../../../model/raiox';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-crm-form',
    templateUrl: './crm-form.component.html',
    styleUrls: ['./crm-form.component.scss'],
    providers: [ClientService]
})
export class CrmFormComponent extends BaseComponent implements OnInit {

    model: Raiox = new Raiox();

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: ClientService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new Raiox();
            } else {
                this.getClient(p['hash']);
            }
        });
    }

    cancelClient(): void {
        this.router.navigate(['crm-register']);
    }

    onSelectedChange(): void {
        // this._mailService.toggleSelectedMail(this.mail.id);
    }

    validate(): boolean {

        if (isNullOrUndefined(this.model.cpf)) {
            return true;
        }
        if (isNullOrUndefined(this.model.name)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_district)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_city)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_state)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_address)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.residential_zip_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.commercial_district)) {
            return true;
        }
        if (isNullOrUndefined(this.model.commercial_city)) {
            return true;
        }
        if (isNullOrUndefined(this.model.commercial_state)) {
            return true;
        }
        if (isNullOrUndefined(this.model.commercial_address)) {
            return true;
        }
        if (isNullOrUndefined(this.model.commercial_number)) {
            return true;
        }
        if (isNullOrUndefined(this.model.commercial_zip_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.ddd)) {
            return true;
        }
        if (isNullOrUndefined(this.model.phone)) {
            return true;
        }
        if (isNullOrUndefined(this.model.email)) {
            return true;
        }
        if (isNullOrUndefined(this.model.birthday)) {
            return true;
        }
        if (isNullOrUndefined(this.model.gender)) {
            return true;
        }
        if (isNullOrUndefined(this.model.civil_status)) {
            return true;
        }
        if (isNullOrUndefined(this.model.observation)) {
            return true;
        }
        if (isNullOrUndefined(this.model.ppex)) {
            return true;
        }
        if (isNullOrUndefined(this.model.income_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.activity_code)) {
            return true;
        }
        if (isNullOrUndefined(this.model.client_origin_code)) {
            return true;
        }
        return false;
    }

    getClient(id): void {
        this.loading.show();
        this.service.getClientDetailId(id).subscribe(
            data => {
                this.model = data as Raiox;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    onSave(): void {
        if (!this.validate()) {
            if (isNullOrUndefined(this.model.id)) {
                this.newClient();
            } else {
                this.editClient();
            }
        }

    }

    newClient(): void {
            this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as Raiox;
                this.loading.hide();
                this.router.navigate(['crm-register']);
            },
            error => {
                super.onError(error);
            });

    }

    editClient(): void {
            this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as Raiox;
                this.loading.hide();
                this.router.navigate(['crm-register']);
            },
            error => {
                super.onError(error);
            });

    }
}
