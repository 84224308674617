import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Attendance, Observation} from '../model/attendance';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class AttendanceService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getAttendance(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/list`;
        return this.http.post(url, request);
    }

    getAttendanceId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: any): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/create`;
        return this.http
            .post(url, model);
    }

    update(model: Attendance): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/remove/${id}`;
        return this.http
            .delete(url);
    }

    createObservation(model: Observation): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/observation/create`;
        return this.http
            .post(url, model);
    }

    updateObservation(model: Observation): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/observation/update`;
        return this.http
            .put(url, model);
    }

    deleteObservation(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/observation/remove/${id}`;
        return this.http
            .delete(url);
    }

    getObservation(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/attendance/observation/list`;
        return this.http.post(url, request);
    }

    getByCpf(cpf: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/get_by_cpf`;
        return this.http.post(url, `{"cpf": "${cpf}"}`);
    }

}
