import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Client} from '../model/client';
import {Raiox} from '../model/raiox';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class ClientService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getClient(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/list`;
        return this.http.post(url, request);
    }

    getClientId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    getClientDetailId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/detail`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: Raiox): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/create`;
        return this.http
            .post(url, model);
    }

    update(model: Raiox): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/remove/${id}`;
        return this.http
            .delete(url);
    }

}
