import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/basecomponent';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {isNullOrUndefined, isUndefined} from 'util';
import {Access, Options, Permission, User} from '../../../model/user';
import {UserService} from '../../../service/user.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    providers: [UserService]
})
export class UserFormComponent extends BaseComponent implements OnInit {

    model: User = new User();
    password: string;

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: UserService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new User();
                this.getPermissions();
            } else {
                this.getUserId(p['hash']);
            }
        });


    }

    cancelUser(): void {
        this.router.navigate(['register-user']);
    }

    validate(): boolean {
        if (isNullOrUndefined(this.model.name)) {
            return true;
        }
        if (isNullOrUndefined(this.model.email)) {
            return true;
        }
        if (isNullOrUndefined(this.model.password)) {
            return true;
        }
        if (isNullOrUndefined(this.password)) {
            return true;
        }
        if (this.password !== this.model.password) {
            return true;
        }
        return false;

    }

    getUserId(id): void {
            this.loading.show();
        this.service.getUserId(id).subscribe(
            data => {
                this.model = data as User;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    getPermissions(): void {
        this.service.getPermissions('').subscribe(
            data => {
                this.model.access = data as Access[];
                for (const item of this.model.access) {
                    for (const perm of item.permissions) {
                        perm.access = new Options();
                    }
                }
            },
            error => {
                super.onError(error);
            });
    }

    onSave(): void {
        if (!this.validate()) {
            if (isNullOrUndefined(this.model.id)) {
                this.newUser();
            } else {
                this.editUser();
            }
        }

    }

    newUser(): void {
            this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as User;
                this.loading.hide();
                this.router.navigate(['register-user']);
            },
            error => {
                super.onError(error);
            });

    }

    editUser(): void {
            this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as User;
                this.loading.hide();
                this.router.navigate(['register-user']);
            },
            error => {
                super.onError(error);
            });

    }

    setAll(p: Permission): void {
        p.access.create = p.access.all;
        p.access.delete = p.access.all;
        p.access.read = p.access.all;
        p.access.update = p.access.all;
    }
}
