import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Insurer} from '../model/insurer';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class InsurerService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getList(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/insurer/list`;
        return this.http.post(url, request);
    }

    create(model: Insurer): Observable<Object> {
        const url = `${this.BASE_URL}/api/insurer/create`;
        return this.http.post(url, model);
    }

    update(model: Insurer): Observable<Object> {
        const url = `${this.BASE_URL}/api/insurer/update`;
        return this.http.put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/insurer/remove/${id}`;
        return this.http.delete(url);
    }


}
