import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {Casualty} from '../../model/casualty';
import {CasualtyService} from '../../service/casualty.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Settings} from '../../settings';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-casualty',
    templateUrl: './casualty.component.html',
    styleUrls: ['./casualty.component.scss'],
    providers: [CasualtyService]
})
export class CasualtyComponent extends BaseComponent implements OnInit {

    displayedColumns = ['license_plate', 'complaint_date', 'occurrence_date', 'date_end', 'cause_description', 'insurer'];

    dataSource: MatTableDataSource<any>;
    model: Casualty = new Casualty();

    request = {
        date_start: '',
        date_end: '',
        insured_cpf: '',
        insured_name: '',
        casualty_order_number: '',
        endorsement_number: '',
        policy_number: '',
        taker_name: '',
        cooperative_system_name: '',
        cooperative_agency_name: '',
        risk_number: '',
        license_plate: '',
        complaint_date: '',
        occurrence_date: '',
        reopening_date: '',
        discharge_date: '',
        page: 1
    };

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: CasualtyService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this.loading.show();
        this.service.getCasualty(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Casualty[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    getExcel(): void {
        if (isNullOrUndefined(this.request.date_start) || this.request.date_start.length === 0) {
            super.openDialogAlert('Error', 'A data inicial é obrigatória!');
            return;
        }

        if (isNullOrUndefined(this.request.date_end) || this.request.date_end.length === 0) {
            super.openDialogAlert('Error', 'A data final é obrigatória!');
            return;
        }
        const token = localStorage.getItem('token') as String;
        let url = `${Settings.BASE_URL}/api/casualty/excel/?access_token=${token}`;
        url += ('&insured_cpf=' + this.request.insured_cpf);
        url += ('&insured_name=' + this.request.insured_name);
        url += ('&policy_number=' + this.request.policy_number);
        url += ('&license_plate=' + this.request.license_plate);
        url += ('&casualty_order_number=' + this.request.casualty_order_number);
        url += ('&complaint_date=' + this.request.complaint_date);
        url += ('&occurrence_date=' + this.request.occurrence_date);
        url += ('&date_start=' + this.request.date_start);
        url += ('&date_end=' + this.request.date_end);
        window.open(url);
    }

    resetFilter(): void {
        this.request.date_start = '';
        this.request.date_end = '';
        this.request.license_plate = '';
        this.request.insured_cpf = '';
        this.request.insured_name = '';
        this.request.casualty_order_number = '';
        this.request.endorsement_number = '';
        this.request.policy_number = '';
        this.request.taker_name = '';
        this.request.cooperative_system_name = '';
        this.request.cooperative_agency_name = '';
        this.request.risk_number = '';
        this.request.complaint_date = '';
        this.request.occurrence_date = '';
        this.request.reopening_date = '';
        this.request.discharge_date = '';
        this.request.page = 1;

        this.getList();
    }

    onEdit(row): void {
        this.router.navigate(['casualty-form/', row.id]);
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
