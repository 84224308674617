import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ClosureService} from '../../service/closure.service';
import {Closure} from '../../model/closure';
import {tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-closure',
    templateUrl: './closure.component.html',
    styleUrls: ['./closure.component.scss'],
    providers: [ClosureService]
})
export class ClosureComponent extends BaseComponent implements OnInit, AfterViewInit {

    displayedColumns = ['month', 'year', 'status_id', 'created_at',
        'closure'];
    request = {
        month: '',
        year: '',
        page: 1
    };
    dataSource: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ClosureService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.tapPaginator())
            )
            .subscribe();
    }

    getList(): void {
        this.loading.show();
        this.service.getClosure(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Closure[]);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    tapPaginator(): void {
        this.request.page = this.paginator.pageIndex + 1;
        this.getList();
    }

}
