import {HttpClient} from '@angular/common/http';
import {ServiceBase} from './service-base';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class DashboardService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }


    getDashboard(): Observable<Object> {
        const url = `${this.BASE_URL}/api/dashboard/count`;
        return this.http.post(url, null);
    }

    getDashboardGraph1(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/dashboard/policy`;
        return this.http.post(url, request);
    }

    getDashboardGraph2(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/dashboard/attendance`;
        return this.http.post(url, request);
    }

}
