import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Casualty} from '../model/casualty';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class CasualtyService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getCasualty(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/casualty/list`;
        return this.http.post(url, request);
    }

    getCasualtyID(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/casualty/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: Casualty): Observable<Object> {
        const url = `${this.BASE_URL}/api/casualty/create`;
        return this.http
            .post(url, model);
    }

    update(model: Casualty): Observable<Object> {
        const url = `${this.BASE_URL}/api/casualty/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/casualty/remove/${id}`;
        return this.http
            .delete(url);
    }

}
