import {Policy} from './policy';
import {Attendance} from './attendance';
import {Casualty} from './casualty';

export class Raiox {

    id: string;
    cpf: string;
    name: string;
    email: string;
    observation: string;
    rg: string;
    rg_shipping_agency: string;
    type: string;
    civil_status: string;
    gender: string;
    income_code: string;
    activity_code: string;
    birthday: string;
    license_emission: string;
    rg_emission: string;
    ppe: number;
    ppex: number;
    ddd: string;
    phone: string;
    fax_ddd: string;
    fax_phone: string;
    residential_address: string;
    residential_number: string;
    residential_complement: string;
    residential_district: string;
    residential_city: string;
    residential_state: string;
    residential_zip_code: string;
    residential_ddd: string;
    residential_phone: string;
    commercial_address: string;
    commercial_number: string;
    commercial_complement: string;
    commercial_district: string;
    commercial_city: string;
    commercial_state: string;
    commercial_zip_code: string;
    commercial_ddd: string;
    commercial_phone: string;
    correspondence_address: string;
    correspondence_number: string;
    correspondence_complement: string;
    correspondence_district: string;
    correspondence_city: string;
    correspondence_state: string;
    correspondence_zip_code: string;
    correspondence_ddd: string;
    correspondence_phone: string;
    client_origin_code: string;
    partner: string;
    partner_type: string;
    partner_phone: string;
    created_at: string;
    updated_at: string;
    casualties: Casualty[];
    policies: Policy[];
    attendances: Attendance[];

    constructor() {

    }

}
