import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {Attendance, Observation, Reason} from '../../model/attendance';
import {AttendanceService} from '../../service/attendance.service';
import {EnumService} from '../../service/enum.service';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Client} from '../../model/client';
import {PolicyService} from '../../service/policy.service';
import {Policy} from '../../model/policy';

@Component({
    selector: 'app-attendance-detail',
    templateUrl: './attendance-detail.component.html',
    styleUrls: ['./attendance-detail.component.scss'],
    providers: [AttendanceService, EnumService, UserService]
})
export class AttendanceDetailComponent extends BaseComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    model: Attendance = new Attendance();
    user: User = new User();
    listUser: User[];
    listAttendance: Attendance[];
    listReason: Reason[];
    observation: Observation = new Observation();
    // editElement: Attendance | null;
    dataSource: MatTableDataSource<any>;
    policy_id: string;

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public enumService: EnumService,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public service: AttendanceService) {
        super(router, dialog, loading);
        this.observation = new Observation();
    }

    request = {
        cpf: '',
        name: '',
        license_plate: '',
        policy: '',
        policy_id: '',
        opening: '',
        closure: '',
        user_id: '',
        status: '',
        reason_id: '',
        email: '',
        phone: '',
        ddd: '',
        page: 1
    };

    ngOnInit(): void {
        const parent = this;
        this.route.params.subscribe(p => {
            if (isNullOrUndefined(p['hash'])) {
                if (isNullOrUndefined(p['policy'])) {
                    this.model = new Attendance();
                    this.model.status = 'open';
                    this.model.user_id = super.getUser().id;
                } else {
                    parent.policy_id = p['policy'];
                    parent.getPolicy(p['cpf']);
                }
            } else {
                this.getAttendanceDetail(p['hash']);
            }
        });

        this.loading.show();
        this.getReasonList();
        this.getUserList();
        this.getList();
    }

    getPolicy(cpf: string): void {
        this.model = new Attendance();
        this.model.cpf = cpf;
        this.getByCpf();
    }

    getByCpf(): void {
        this.loading.show();
        this.service.getByCpf(this.model.cpf).subscribe(
            data => {
                this.model = new Attendance();
                const c = data as Client;
                this.model.status = 'open';
                this.model.cpf = c.cpf;

                console.log(c.phone.toString().length);
                if (c.phone.toString().length === 11) {
                    this.model.ddd = c.phone.toString().substr(0, 2);
                    this.model.phone = c.phone.toString().substring(2, c.phone.toString().length);
                } else {
                    this.model.ddd = c.ddd;
                    this.model.phone = c.phone;
                }

                this.model.client_id = c.id;
                this.model.name = c.name;
                this.model.email = c.email;
                this.model.policies = c.policies;
                if (!isNullOrUndefined(this.policy_id)) {
                    for (const x of c.policies) {
                        if (x.id.toLowerCase() === this.policy_id.toLowerCase()) {
                            this.model.policy = x.policy_number.toString();
                            this.model.license_plate = x.license_plate;
                            console.log(this.model.policy);
                        }
                    }
                }
                this.model.user_id = super.getUser().id;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    getAttendanceDetail(id: string): void {
        this.service.getAttendanceId(id).subscribe(
            data => {
                this.model = data as Attendance;
            },
            error => {
                super.onError(error);
            });
    }

    getReasonList(): void {
        this.enumService.getReason().subscribe(
            data => {
                this.listReason = data as Reason[];
            },
            error => {
                super.onError(error);
            }
        );
    }

    getUserList(): void {
        this.userService.list('{"page":1}').subscribe(
            data => {
                this.listUser = data as User[];
            },
            error => {
                super.onError(error);
            }
        );
    }

    getList(): void {
        this.loading.show();
        this.service.getAttendance(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Attendance[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    addObservation(): void {
        this.loading.show();
        this.observation.attendance_id = this.model.id;
        this.service.createObservation(this.observation).subscribe(
            data => {
                const ob = data as Observation;
                ob.user = super.getUser();
                this.model.observations.push(ob);
                this.observation = new Observation();
                this.loading.hide();
                this.openDialogAlert('Success', 'Observação adicionada com sucesso');
            },
            error => {
                super.onError(error);
            });
    }

    updateAttendance(): void {
        this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.loading.hide();
                this.openDialogAlert('Success', 'Atendimento alterado com sucesso');
            },
            error => {
                super.onError(error);
            });
    }

    onSave(): void {
        if (isNullOrUndefined(this.model.id) || this.model.id.length === 0) {
            this.newAttendance();
        } else {
            this.updateAttendance();
        }
    }

    newAttendance(): void {
        this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as Attendance;
                this.model.observations = [];
                this.loading.hide();
                this.openDialogAlert('Success', 'Atendimento adicionado com sucesso');
            },
            error => {
                super.onError(error);
            });

    }

    setPlate(): void {
        const pol = this.model.policies.find(v => v.policy_number.toString() === this.model.policy.toString());
        console.log(pol);
        this.model.license_plate = pol.license_plate;
        console.log(this.model.license_plate);
    }
}
