import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/basecomponent';
import {UserService} from '../../../service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {Coverage} from '../../../model/coverage';
import {CoverageService} from '../../../service/coverage.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-coverage-form',
    templateUrl: './coverage-form.component.html',
    styleUrls: ['./coverage-form.component.scss'],
    providers: [CoverageService]
})
export class CoverageFormComponent extends BaseComponent implements OnInit {

    model: Coverage = new Coverage();

    constructor(public router: Router,
                public route: ActivatedRoute,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: CoverageService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new Coverage();
            } else {
                this.getCoverage(p['hash']);
            }
        });
    }

    cancelCoverage(): void {
        this.router.navigate(['register-coverage']);
    }

    getCoverage(id): void {
            this.loading.show();
        this.service.getCoverageId(id).subscribe(
            data => {
                this.model = data as Coverage;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    onSave(): void {
        if (isNullOrUndefined(this.model.id)) {
            this.newCoverage();
        } else {
            this.editCoverage();
        }
    }

    newCoverage(): void {
        if (isNullOrUndefined(this.model.name) || this.model.name === ''){
            return;
        }
        if (isNullOrUndefined(this.model.description) || this.model.description === ''){
            return;
        }
            this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as Coverage;
                this.loading.hide();
                this.router.navigate(['register-coverage']);
            },
            error => {
                super.onError(error);
            });

    }

    editCoverage(): void {
        if (isNullOrUndefined(this.model.name) || this.model.name === ''){
            return;
        }
        if (isNullOrUndefined(this.model.description) || this.model.description === ''){
            return;
        }
            this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as Coverage;
                this.loading.hide();
                this.router.navigate(['register-coverage']);
            },
            error => {
                super.onError(error);
            });

    }

}
