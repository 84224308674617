import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {PropositionFormsService} from '../../service/proposition-forms.service';
import {PropositionForm} from '../../model/proposition-form';
import {Settings} from '../../settings';

@Component({
    selector: 'app-end-out-of-pat',
    templateUrl: './end-out-of-pat.component.html',
    styleUrls: ['./end-out-of-pat.component.scss'],
    providers: [PropositionFormsService]
})

export class EndOutOfPatComponent extends BaseComponent implements OnInit {

    columnsToDisplay = ['policy', 'name', 'endorsement', 'plate', 'created_at', 'pdf', 'delete'];
    dataSource: MatTableDataSource<any>;
    request = {
        type: 'end_out_pat',
        start: '',
        end: '',
        page: 1
    };

    constructor(public router: Router,
                public dialog: MatDialog,
                public service: PropositionFormsService,
                public loading: NgxSpinnerService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this.loading.show();
        this.service.getList(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as PropositionForm[]);
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    delete(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir esse formulaŕio?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.remove(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Formulário removido com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

    getEnd(end: string): string {
        switch (end) {
            case 'e_address':
                return 'Alteração do endereço de correspondência';
                break;
            case 'e_plate':
                return 'Alteração de placa';
                break;
            case 'e_year':
                return 'Alteração do ano/modelo do veículo';
                break;
            case 'e_color':
                return 'Alteração de cor';
                break;
            case 'e_name_s':
                return 'Alteração do nome do Segurado';
                break;
            case 'e_name_d':
                return 'Alteração do nome do condutor';
                break;
            case 'e_cancel_d':
                return 'Cancelamento da apólice tendo em vista duplicidade';
                break;
            case 'e_cancel_r':
                return 'Cancelamento da apólice tendo em vista recusa';
                break;
            case 'e_cancel_p':
                return 'Cancelamento pro rata, por decisão da Seguradora';
                break;
            case 'e_cancel_s':
                return 'Cancelamento do seguro, por decisão do Segurado';
                break;
            case 'e_excl':
                return 'Exclusão de avarias';
                break;
            case 'e_inc_b':
                return 'Inclusão de Cláusula Beneficiária';
                break;
            case 'e_inc_p':
                return 'Inclusão de placa';
                break;
            case 'e_inc_o':
                return 'Inclusão de Opcionais';
                break;
            case 'e_other':
                return 'Outras alterações';
                break;
            default:
                return '';
                break;
        }
    }

    getPdf(id: string): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/proposition_forms/pdf/${id}?access_token=${token}`;
        window.open(url);
    }

}
