import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DashboardService} from '../../service/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [DashboardService]
})
export class DashboardComponent extends BaseComponent implements OnInit {

    // casualties: 0;
    clients: 0;
    policies: 0;
    attendances: 0;
    attend3days: 0;

    attendanceTotal: 0;

    chartAttendanceOptions = {
        spanGaps: false,
        legend: {
            display: true
        },
        maintainAspectRatio: false,
        tooltips: {
            position: 'nearest',
            mode: 'index',
            intersect: false
        },
        layout: {
            padding: {
                left: 24,
                right: 32
            }
        },
        elements: {
            point: {
                radius: 2,
                borderWidth: 2,
                hoverRadius: 4,
                hoverBorderWidth: 2
            }
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        fontColor: 'rgba(0,0,0,0.54)'
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 0
                    },
                    ticks: {
                        stepSize: 0,
                        beginAtZero: true
                    }
                }
            ]
        },
        plugins: {
            filler: {
                propagate: false
            }
        }
    };

    chartColors = [

        {
            borderColor: '#7eccc9',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            pointBackgroundColor: '#7eccc9',
        },
        {
            borderColor: '#7986cb',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            pointBackgroundColor: '#7986cb',
        },
        {
            borderColor: '#c5cae9',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            pointBackgroundColor: '#c5cae9',
        },
        {
            borderColor: '#fcb0b3',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            pointBackgroundColor: '#fcb0b3',
        },
    ];
    chartAttendanceLabels = [];

    chartDataAttendance = [];
    showGraphAttendance = false;

    chartPieLabels: string[] = [];
    chartPieData: any = [];
    pieChartType = 'pie';
    Colors: any =
        [
            {
                backgroundColor: [
                    '#3949ab',
                    '#5c6bc0',
                    '#9fa8da',
                    '#283593',
                    '#c5cae9',
                    '#2035bd',
                ]
            }
        ];

    chartPolicyOptions = {
        spanGaps: false,
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        tooltips: {
            position: 'nearest',
            mode: 'index',
            intersect: false
        },
        layout: {
            padding: {
                left: 24,
                right: 32
            }
        },
        elements: {
            point: {
                radius: 4,
                borderWidth: 2,
                hoverRadius: 4,
                hoverBorderWidth: 2
            }
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        fontColor: 'rgba(0,0,0,0.54)'
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 16
                    },
                    ticks: {
                        stepSize: 0,
                        beginAtZero: true
                    }
                }
            ]
        },
        plugins: {
            filler: {
                propagate: false
            }
        }
    };
    chartCasualtyOptions = {
        spanGaps: false,
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        tooltips: {
            position: 'nearest',
            mode: 'index',
            intersect: false
        },
        layout: {
            padding: {
                left: 24,
                right: 32
            }
        },
        elements: {
            point: {
                radius: 4,
                borderWidth: 2,
                hoverRadius: 4,
                hoverBorderWidth: 2
            }
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: 'rgba(0,0,0,0.54)'
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 16
                    },
                    ticks: {
                        stepSize: 50,
                        beginAtZero: true
                    }
                }
            ]
        },
        plugins: {
            filler: {
                propagate: false
            }
        }
    };
    chartColorsPolicy = [
        {
            borderColor: '#3949ab',
            backgroundColor: '#3949ab',
            pointBackgroundColor: '#3949ab',
            pointHoverBackgroundColor: '#3949ab',
            pointBorderColor: '#ffffff',
            pointHoverBorderColor: '#ffffff'
        }
        // {
        //     borderColor: '#bf360c',
        //     backgroundColor: 'rgba(0, 0, 0, 0.0)',
        //     pointBackgroundColor: '#bf360c',
        //     pointHoverBackgroundColor: '#bf360c',
        //     pointBorderColor: '#ffffff',
        //     pointHoverBorderColor: '#ffffff'
        // }
    ];
    chartPolicyLabels = [];
    chartOptions = [];
    chartDataPolicy = [];

    showGraphPolicy = false;

    nowMonth = new Date().getMonth() + 1;

    requestGraph1 = {
        month_start: '1',
        year_start: new Date().getFullYear() - 1,
        month_end: '12',
        year_end: new Date().getFullYear(),
    };

    requestGraph2 = {
        year: new Date().getFullYear(),
        month: this.nowMonth.toString()
    };

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: DashboardService) {
        super(router, dialog, loading);

    }

    ngOnInit(): void {
        this.getListCount();
        this.getListGraph1();
        this.getListGraph2();
    }

    getListCount(): void {
        this.loading.show();
        this.service.getDashboard().subscribe(
            data => {
                // this.casualties = data['casualties'];
                this.clients = data['clients'];
                this.policies = data['policies'];
                this.attendances = data['attendances'];
                this.attend3days = data['attend3days'];
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    getListGraph1(): void {

        this.loading.show();
        this.showGraphPolicy = false;
        this.service.getDashboardGraph1(this.requestGraph1).subscribe(
            data => {
                this.chartOptions = [];
                this.chartDataPolicy = [
                    {
                        label: 'Apólices',
                        data: [],
                        fill: 'start'
                    }
                ];
                const result = data as GraphDataPolicy[];
                this.chartPolicyLabels = [];
                result.forEach((arg) => {
                    this.chartPolicyLabels.push(arg.date.toString());
                    this.chartDataPolicy[0].data.push(arg.policy);
                });

                this.chartOptions.push(this.chartPolicyOptions);
                this.showGraphPolicy = true;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    getListGraph2(): void {
        this.loading.show();
        this.showGraphAttendance = false;
        this.service.getDashboardGraph2(this.requestGraph2).subscribe(
            data => {
                this.chartAttendanceLabels = [];
                this.chartDataAttendance = [];
                this.chartPieData = [];
                this.chartPieLabels = [];
                this.chartDataAttendance = [];
                this.attendanceTotal = 0;
                const result = data as GraphData;

                let index = 0;
                result.graph_line.forEach((a) => {
                    this.chartDataAttendance.push({
                        label: a.name,
                        data: [],
                        fill: 'start'
                    });

                    a.attendance.forEach((t) => {
                        if (index === 0) {
                            this.chartAttendanceLabels.push(t.data);
                        }
                        this.chartDataAttendance[index].data.push(t.count);
                    });

                    index += 1;
                });

                result.graph_pizza.forEach((a) => {
                    this.chartPieData.push(a.total);

                    this.chartPieLabels.push(a.name);
                    this.attendanceTotal += a.total;
                    index += 1;
                });

                this.showGraphAttendance = true;
                this.loading.hide();
            }, error => {
                super.onError(error);
            });
    }
}

export class GraphData {
    graph_line: [GraphAttendance];
    graph_pizza: [GraphPizza];

    constructor() {
    }
}

export class GraphAttendance {
    name: string;
    attendance: [GraphDayData];

    constructor() {
    }
}

export class GraphPizza {
    id: string;
    name: string;
    total: number;

    constructor() {
    }
}

export class GraphDayData {
    data: string;
    count: string;

    constructor() {
    }
}

export class GraphDataPolicy {
    date: string;
    policy: number;

    // casualty: number;

    constructor() {
    }
}
