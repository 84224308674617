import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Client} from '../../model/client';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {ClientService} from '../../service/client.service';
import {tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-crm-raiox',
  templateUrl: './crm-raiox.component.html',
  styleUrls: ['./crm-raiox.component.scss'],
    providers: [ClientService]
})
export class CrmRaioxComponent extends BaseComponent implements OnInit  {

    displayedColumns = ['cpf', 'name', 'email',
        'gender'];
    dataSource: MatTableDataSource<any>;
    model: Client = new Client();

    request = {
        name: '',
        cpf: '',
        email: '',
        gender: '',
        civil_status: '',
        state: '',
        city: '',
        page: 1
    };

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ClientService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }


    getList(): void {
            this.loading.show();
        this.service.getClient(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Client[]);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }


    resetFilter(): void {
        this.request.cpf = '';
        this.request.name = '';
        this.request.email = '';
        this.request.gender = '';
        this.request.civil_status = '';
        this.request.state = '';
        this.request.city = '';
        this.request.page = 1;

        this.getList();
    }

    detail(id): void {
        this.router.navigate(['raiox-detail/', id]);
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) { return; }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
