import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Import} from '../model/import';
import {Observable} from 'rxjs';

@Injectable()
export class ImportService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getImport(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/import/list`;
        return this.http.post(url, request);
    }

    sendFiles(files: File[], type: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/import`;
        return Observable.create(observer => {

            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            for (let i = 0; i < files.length; i++) {
                formData.append('file' + i.toString(), files[i], files[i]['name']);
            }

            formData.append('type', type);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
