import {Component, OnInit, ViewChild} from '@angular/core';
import {isUndefined} from 'util';
import {ClientService} from '../../../service/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {BaseComponent} from '../../../base/basecomponent';
import {Raiox} from '../../../model/raiox';
import {Policy} from '../../../model/policy';
import {Casualty} from '../../../model/casualty';
import {Attendance, Observation, Reason} from '../../../model/attendance';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {User} from '../../../model/user';
import {AttendanceService} from '../../../service/attendance.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-raiox-detail',
    templateUrl: './raiox-detail.component.html',
    styleUrls: ['./raiox-detail.component.scss'],
    providers: [ClientService, AttendanceService]
})
export class RaioxDetailComponent extends BaseComponent implements OnInit {

    displayedColumnsPolicy = ['numberPolicy', 'type',
        'plate', 'policyholder', 'document', 'date', 'validityInit'];
    dataSourcePolicy: any;
    @ViewChild(MatPaginator) paginatorPolicy: MatPaginator;
    @ViewChild(MatSort) sortPolicy: MatSort;


    displayedColumnsCasualty = ['policy_number', 'insured_name', 'taker_name', 'complaint_date', 'occurrence_date', 'discharge_date', 'status'];
    dataSourceCasualty: any;
    @ViewChild(MatPaginator) paginatorCasualty: MatPaginator;
    @ViewChild(MatSort) sortCasualty: MatSort;


    @ViewChild(MatSort) sortAttendance: MatSort;

    observationEdit: Observation;

    dataSourceAttendance: MatTableDataSource<any>;
    displayedColumnsAttendance = ['cpf', 'name', 'policy', 'license_plate',
        'created_at', 'closure', 'status', 'reason'];
    expandedElement: Attendance | null;
    editElement: Attendance | null;

    model: Raiox = new Raiox();

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public service: ClientService,
                public loading: NgxSpinnerService,
                public serviceAttendance: AttendanceService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new Raiox();
            } else {
                this.getClient(p['hash']);
            }
        });
        this.observationEdit = new Observation();
    }

    cancelRaiox(): void {
        this.router.navigate(['crm-raiox']);
    }

    onEditPolicy(id): void {
        this.router.navigate(['policy-form', id]);
    }

    onEditCasualty(row): void {
        this.router.navigate(['casualty-form/', row.id]);
    }

    getClient(id): void {
        this.loading.show();
        this.service.getClientId(id).subscribe(
            data => {
                // this.dialog.closeAll();
                this.model = data as Raiox;

                this.dataSourcePolicy = new MatTableDataSource(this.model.policies);
                this.dataSourcePolicy.sort = this.sortPolicy;

                this.dataSourceCasualty = new MatTableDataSource(this.model.casualties);
                this.dataSourceCasualty.sort = this.sortCasualty;

                this.dataSourceAttendance = new MatTableDataSource(this.model.attendances);
                this.dataSourceAttendance.sort = this.sortAttendance;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    setElement(element): void {
        this.expandedElement = this.expandedElement === element ? null : element;
        this.editElement = Object.assign({}, this.expandedElement);
    }

    addObservation(att: Attendance): void {
        this.loading.show();
        this.observationEdit.attendance_id = att.id;
        this.serviceAttendance.createObservation(this.observationEdit).subscribe(
            data => {
                const ob = data as Observation;
                console.log(super.getUser());
                ob.user = super.getUser();
                att.observations.push(ob);
                this.observationEdit = new Observation();
                this.loading.hide();
                this.openDialogAlert('Success', 'Observação adicionada com sucesso');
            },
            error => {
                super.onError(error);
            });
    }

    updateAttendance(): void {
        this.loading.show();
        this.serviceAttendance.update(this.editElement).subscribe(
            data => {
                this.loading.hide();
                this.route.params.subscribe(p => {
                    this.getClient(p['hash']);
                });
            },
            error => {
                super.onError(error);
            });
    }

    deleteAttendance(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir esse atendimento?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Atendimento deletado com sucesso');
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });

    }

}
