import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {ImportService} from '../../service/import.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {ImporterStatus} from '../../model/importer-status';
import {Settings} from '../../settings';

@Component({
    selector: 'app-import-list',
    templateUrl: './import-list.component.html',
    styleUrls: ['./import-list.component.scss'],
    providers: [ImportService]
})
export class ImportListComponent extends BaseComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSource<any>;

    columnsToDisplay = ['name', 'status', 'type', 'error_message', 'start', 'end', 'download'];

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ImportService) {
        super(router, dialog, loading);
    }

    request = {
        status: '',
        type: '',
        name: '',
        start: '',
        end: '',
        page: 1
    };

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this.loading.show();
        this.service.getImport(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as ImporterStatus[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.status = '';
        this.request.name = '';
        this.request.start = '';
        this.request.end = '';
        this.request.page = 1;
        this.getList();
    }

    getStatus(status: string): string {
        switch (status) {
            case 'uploaded':
                return 'Enviado';
                break;
            case 'in_process':
                return 'Processando';
                break;
            case 'done':
                return 'Processado';
                break;
            case 'error':
                return 'Falha';
                break;
            default:
                return '';
                break;
        }
    }

    getType(type: string): string {
        switch (type) {
            case 'mapfre_policy':
                return 'MAPFRE Apólice';
                break;
            case 'mapfre_sinis':
                return 'MAPFRE Sinistro';
                break;
            case 'hdi_policy':
                return 'HDI Apólice';
                break;
            case 'hdi_sinis':
                return 'HDI Sinistro';
                break;
            case 'tokio_policy':
                return 'TOKIO Apólice';
                break;
            case 'tokio_sinis':
                return 'TOKIO Sinistro';
                break;
            case 'clients_excel':
                return 'EXCEL Clientes';
                break;
            default:
                return '';
                break;
        }
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

    getStatusClass(status: string): string {
        switch (status) {
            case 'uploaded':
                return 'label-uploaded';
                break;
            case 'in_process':
                return 'label-in-process';
                break;
            case 'done':
                return 'label-done';
                break;
            case 'error':
                return 'label-error';
                break;
            default:
                return '';
                break;
        }
    }

    downloadModel(): void {
        window.open(Settings.CLIENTS_FILE_URL);
    }

    getFile(id: string): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/import/download/${id}?access_token=${token}`;
        window.open(url);
    }
}
