import {Insurer} from './insurer';

export class Coverage {

    id: string;
    name: string;
    description: string;
    insurer: Insurer;
    insurer_id: string;
    emission: boolean;


    constructor(){

    }
}