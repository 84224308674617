///<reference path="../../../node_modules/@angular/http/src/base_request_options.d.ts"/>
/**
 * Created by Raquel on 13/12/2017.
 */

// import {Settings} from '../settings';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Settings} from '../settings';

export class ServiceBase {

  public BASE_URL = Settings.BASE_URL;
  // public BASE_URL = 'http://localhost:4200';
  public DEBUG = '?XDEBUG_SESSION_START=PHPSTORM';

  constructor(public  http: HttpClient) {
  }

  public getToken(): String {
    return localStorage.getItem('token') as String;
  }

  public header(): Object {

    const _headers: HttpHeaders = new HttpHeaders();
    _headers.append('Content-Type', 'application/json');

    const token = localStorage.getItem('token') as String;

    if (token != null) {
      _headers.append('Authorization', 'Bearer ' + token);
    }

    return {headers: _headers};

  }

  public onError(error: Response | any): Observable<Object> {
    return Observable.throw(error.message || error);
  }

  public extractData(res: any) {
    const body = res.json();
    return body || {};
  }

}
