import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {BaseComponent} from '../../../base/basecomponent';
import {Router} from '@angular/router';
import {UserService} from '../../../service/user.service';
import {User} from '../../../model/user';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-main-dialog',
    templateUrl: './main-dialog.component.html',
    styleUrls: ['./main-dialog.component.scss'],
    providers: [UserService],
    encapsulation: ViewEncapsulation.None
})
export class MainDialogComponent extends BaseComponent implements OnInit {

    model: User = new User();

    password_confirm: string;

    request_password = {
        old_password: '',
        password: ''
    };

    verify: boolean;

    constructor(public dialogRef: MatDialogRef<MainDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: User,
                public service: UserService,
                public router: Router,
                public loading: NgxSpinnerService,
                public dialog: MatDialog) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.model = super.getUser();
        this.password_confirm = '';
        this.request_password.old_password = this.model.password;
        this.verify = false;
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    onValidate(): boolean {
        if (this.isEmpty(this.password_confirm)) {
            this.openDialogAlert('Success', 'Preencha a confirmação de senha');
            return true;
        }
        if (this.isEmpty(this.request_password.password)) {
            this.openDialogAlert('Success', 'Preencha a nova senha');
            return true;
        }
        if (this.password_confirm !== this.request_password.password) {
            this.openDialogAlert('Success', 'A confirmação da senha é inválida');
            return true;
        }
        return false;
    }


    changePassword(): void {
        if (this.onValidate()) {
            return;
        }
        this.model.password = this.request_password.password;
        this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.loading.hide();
                this.openDialogAlert('Success', 'Senha alterada com sucesso');
            },
            error => {
                super.onError(error);
            });
    }

}
