import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {PropositionForm} from '../../model/proposition-form';
import {PropositionFormsService} from '../../service/proposition-forms.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {isNullOrUndefined, isUndefined} from 'util';
import {Settings} from '../../settings';

@Component({
    selector: 'app-new-form-out-of-pat',
    templateUrl: './new-form-out-of-pat.component.html',
    styleUrls: ['./new-form-out-of-pat.component.scss'],
    providers: [PropositionFormsService]
})
export class NewFormOutOfPatComponent extends BaseComponent implements OnInit {

    model = new PropositionForm();

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public service: PropositionFormsService,
                public loading: NgxSpinnerService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['id'])) {
                this.model = new PropositionForm();
                this.model.type = p['type'];
            } else {
                this.getForm(p['id']);
            }
        });
    }

    getForm(id): void {
        this.loading.show();
        this.service.get(id).subscribe(
            data => {
                this.model = data as PropositionForm;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    onSave(): void {
        if (isNullOrUndefined(this.model.id)) {
            this.newClient();
        } else {
            this.editClient();
        }
    }

    newClient(): void {
        this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as PropositionForm;
                this.loading.hide();
                this.openDialogAlert('Success', 'Formulário cadastrado com sucesso');
            },
            error => {
                super.onError(error);
            });

    }

    editClient(): void {
        this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as PropositionForm;
                this.loading.hide();
                this.openDialogAlert('Success', 'Formulário alterado com sucesso');
            },
            error => {
                super.onError(error);
            });
    }

    getPdf(): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/proposition_forms/pdf/${this.model.id}?access_token=${token}`;
        window.open(url);
    }

    copyFromInsured(): void {
        this.model.driver_name = this.model.name;
        this.model.driver_cpf_cnpj = this.model.cpf_cnpj;
        this.model.driver_marital = this.model.marital;
        this.model.driver_birth = new Date(this.model.birth).toISOString().substring(0, 10);;
        this.model.driver_gender = this.model.gender;
        this.model.driver_zip_code = this.model.zip_code;
        this.model.driver_city = this.model.city;
        this.model.driver_state = this.model.state;
        console.log(this.model);
    }

}
