import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class EnumService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }


    getBranch(): Observable<Object> {
        const url = `${this.BASE_URL}/api/branch/list`;
        return this.http.post(url, null);
    }

    getEndorsementType(): Observable<Object> {
        const url = `${this.BASE_URL}/api/endorsement/type/list`;
        return this.http.post(url, null);
    }

    getMovementType(): Observable<Object> {
        const url = `${this.BASE_URL}/api/movement/type/list`;
        return this.http.post(url, null);
    }

    getIncome(): Observable<Object> {
        const url = `${this.BASE_URL}/api/income/list`;
        return this.http.post(url, null);
    }

    getReason(): Observable<Object> {
        const url = `${this.BASE_URL}/api/reason/list`;
        return this.http.post(url, null);
    }

    getStatus(): Observable<Object> {
        const url = `${this.BASE_URL}/api/status/list`;
        return this.http.post(url, null);
    }

    getActivity(): Observable<Object> {
        const url = `${this.BASE_URL}/api/activity/list`;
        return this.http.post(url, null);
    }

    getCoverage(): Observable<Object> {
        const url = `${this.BASE_URL}/api/coverage/list`;
        return this.http.post(url, null);
    }

}
