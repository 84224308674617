/**
 * Created by raquel on 28/11/17.
 */

import {Router} from '@angular/router';
import './extends';
import {Observable} from 'rxjs/';
import {User} from '../model/user';
import * as _ from 'underscore';
import {HttpClientModule} from '@angular/common/http';

import {LoadingComponent} from '../view/dialog/loading/loading.component';
import {AlertComponent} from '../view/dialog/alert/alert.component';
import {MatDialog} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {NgxSpinnerService} from 'ngx-spinner';

declare function changeTab(id: string, tab: string): any;

export class BaseComponent {

  constructor(public router: Router,
              public dialog: MatDialog,
              public loading: NgxSpinnerService) {
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('user')) as User;
  }

  setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  formatDate(date: string): string {
    const parts = date.split('/');
    return parts[2] + '-' + parts[1] + '-' + parts[0];
  }

  formatDateUpdate(date: string): string {
    const parts = date.split('-');
    return parts[2] + '/' + parts[1] + '/' + parts[0];
  }

  getDateNowNoFormat(days: number = 0): string {
    const date = new Date();
    if (days !== 0) {
      date.setDate(date.getDate() + days);
    }

    const day = date.getDate().toString().padLeft(2, '0');
    const month = (date.getMonth() + 1).toString().padLeft(2, '0');
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }


  getPager(items: number, currentPage: number = 1, pageSize: number = 10) {

    // calcula o total de páginas
    const totalPages = Math.ceil(items / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // menos de 10 páginas
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // clacula o começo e o fim dos items da página
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, items - 1);

    //
    const pages = _.range(startPage, endPage + 1);

    // retornos
    return {
      items: items,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };

  }


// ---------------------------- New Opa ----------------------------

  onError(error): void {
    this.loading.hide();
    console.log(error);

    if (typeof error === 'string') {
        this.openDialogAlert('Error', JSON.parse(error).message);
        return;
    }
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return;
    }

    if (error.status === 400) {
      this.openDialogAlert('Error', error['error']['message']);
      return;
    }
    this.openDialogAlert('Error', 'Error');
    return (error);
  }

  openDialogAlert(tp: string, desc: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      // type = Alert, Error e Success
      data: {type: tp, description: desc},
      panelClass: 'alertClass',
      disableClose: true,
      hasBackdrop: false,
      width: '400px',
      maxWidth: '400px',
      minWidth: '400px'
    });
    return dialogRef;

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(result);
    // });
  }

  isEmpty (item: any): boolean {
    if (!item || item === null || item.toString() === ''){
      return true;
    }
    return false;
  }

  translateStatus(status: string): string {
    switch (status) {
      case 'open':
        return 'ABERTO';

      case 'approved':
        return 'APROVADO';

      case 'finalized':
        return 'FINALIZADO';

      case 'disapproved':
        return 'REPROVADO';

      default:
        return '?';
    }
  }



  translateTypeUser(type: string): string {
    switch (type) {
      case 'user':
        return 'Contribuinte';

      case 'admin':
        return 'Usuário';

      case 'colab':
        return 'Colaborador';

      default:
        return '?';
    }
  }



























}
