
import {Insurer} from './insurer';
import {Coverage} from './coverage';

export class Proposition {

    id: string;
    activity_id: string;
    address: string;
    assistance_coverage: string;
    beneficiary_birthday: string;
    beneficiary_cpf: string;
    beneficiary_document_type: string;
    beneficiary_gender: string;
    beneficiary_name: string;
    beneficiary_participation: string;
    beneficiary_relationship: string;
    beneficiary_rg: string;
    branch_id: string;
    chassis: string;
    city: string;
    civil_responsability_coverage: string;
    client_id: string;
    color: string;
    coverage_id: string;
    cpf_cnpj: string;
    district: string;
    eletrical_damage_coverage: string;
    email: string;
    gale_impact_coverage: string;
    glass_coverage: string;
    inclusion: string;
    income_id: string;
    insurance: string;
    insured_birthday: string;
    insured_civil_status: string;
    insured_gender: string;
    insured_name: string;
    insured_rg: string;
    insurer_id: string;
    insurer_name: string;
    license_plate: string;
    manufacturer: string;
    manufacturing_year: string;
    model: string;
    model_year: string;
    movement_type_id: string;
    natural_coverage: string;
    number: string;
    observation: string;
    other_phone: string;
    person_type: string;
    phone: string;
    policy_number: string;
    ppe: number;
    ppex: number;
    premium_total: string;
    proposition: string;
    proposition_number: string;
    random_branch: string;
    recomposition_demage_coverage: string;
    refuse: string;
    renavam: string;
    rent_payment_coverage: string;
    residential_phone: string;
    rg: string;
    risk_address: string;
    risk_beneficiary_cpf_cnpj: string;
    risk_beneficiary_name: string;
    risk_district_city: string;
    risk_state: string;
    risk_zip_code: string;
    spouse_cpf: string;
    spouse_document_type: string;
    spouse_gender: string;
    spouse_name: string;
    spouse_rg: string;
    state: string;
    status: string;
    stipulating_cpf: string;
    stipulating_name: string;
    theft_coverage: string;
    use: string;
    validity_end: string;
    validity_start: string;
    vehicle_bonus_class: string;
    vehicle_corporal_damage: string;
    vehicle_franchise_type: string;
    vehicle_material_damage: string;
    water_damage_coverage: string;
    zero_km: boolean;
    zip_code: string;

    movement_type: MovementType;
    insurer: Insurer;
    activity: Activity;
    branch: Branch;
    coverage: Coverage;

    controllers: Controller[];

    constructor() {
        this.controllers = [];
        this.id = '';
        this.address = '';
        this.assistance_coverage = '';
        this.beneficiary_cpf = '';
        this.beneficiary_document_type = '';
        this.beneficiary_gender = '';
        this.beneficiary_name = '';
        this.beneficiary_participation = '';
        this.beneficiary_relationship = '';
        this.beneficiary_rg = '';
        this.chassis = '';
        this.city = '';
        this.civil_responsability_coverage = '';
        this.color = '';
        this.cpf_cnpj = '';
        this.district = '';
        this.eletrical_damage_coverage = '';
        this.email = '';
        this.gale_impact_coverage = '';
        this.glass_coverage = '';
        this.inclusion = '';
        this.insurance = '';
        this.insured_civil_status = '';
        this.insured_gender = '';
        this.insured_name = '';
        this.insured_rg = '';
        this.insurer_name = '';
        this.license_plate = '';
        this.manufacturer = '';
        this.manufacturing_year = '';
        this.model = '';
        this.model_year = '';
        this.natural_coverage = '';
        this.number = '';
        this.observation = '';
        this.other_phone = '';
        this.person_type = '';
        this.phone = '';
        this.policy_number = '';
        this.ppe = 0;
        this.ppex = 0;
        this.premium_total = '';
        this.proposition = '';
        this.proposition_number = '';
        this.random_branch = '';
        this.recomposition_demage_coverage = '';
        this.refuse = '';
        this.renavam = '';
        this.rent_payment_coverage = '';
        this.residential_phone = '';
        this.rg = '';
        this.risk_address = '';
        this.risk_beneficiary_cpf_cnpj = '';
        this.risk_beneficiary_name = '';
        this.risk_district_city = '';
        this.risk_state = '';
        this.risk_zip_code = '';
        this.spouse_cpf = '';
        this.spouse_document_type = '';
        this.spouse_gender = '';
        this.spouse_name = '';
        this.spouse_rg = '';
        this.state = '';
        this.status = '';
        this.stipulating_cpf = '';
        this.stipulating_name = '';
        this.theft_coverage = '';
        this.use = '';
        this.validity_end = '';
        this.validity_start = '';
        this.vehicle_bonus_class = '';
        this.vehicle_corporal_damage = '';
        this.vehicle_franchise_type = '';
        this.vehicle_material_damage = '';
        this.water_damage_coverage = '';
        this.zero_km = false;
        this.zip_code = '';
    }

}

export class Branch {
    id: string;
    number: number;
    name: string;
    description: string;

    constructor() {

    }
}

export class MovementType {
    id: string;
    description: string;
    endorsement_type_id: string;

    constructor() {

    }
}

export class Controller {

    cpf: string;
    name: string;
    ppe: string;

    constructor() {

    }

}

export class Activity {
    id: string;
    name: string;

    constructor() {

    }
}
