import {Component, OnInit, ViewChild} from '@angular/core';
import {Proposition} from '../../model/proposition';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {EnumService} from '../../service/enum.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../base/basecomponent';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-register-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
    providers: [UserService, EnumService]
})
export class UserComponent extends BaseComponent implements OnInit {


    displayedColumns = ['name', 'email', 'created_at', 'last_access', 'delete'];

    dataSource: any;
    model: User = new User();

    listStatus: any = [];

    request = {
        name: '',
        email: '',
        page: 1
    };

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public service: UserService,
                public loading: NgxSpinnerService,
                public serviceEnum: EnumService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
        this.getListStatus();
    }

    getList(): void {
            this.loading.show();
        this.service.getUser(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Proposition[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.name = '';
        this.request.email = '';
        this.request.page = 1;

        this.getList();
    }

    getListStatus(): void {
        this.serviceEnum.getStatus().subscribe(
            data => {
                this.listStatus = data;
            },
            error => {
                super.onError(error);
            });
    }

    onEdit(id): void {
        this.router.navigate(['user-form/', id]);
    }

    newUser(): void {
        this.router.navigate(['user-form']);
    }

    delete(id): void {

        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir esse usuário?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                    this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Usuário deletado com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) { return; }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }


}
