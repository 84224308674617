import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user';
import {BaseComponent} from '../../base/basecomponent';
import {MatDialog} from '@angular/material';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [UserService]
})
export class LoginComponent extends BaseComponent implements OnInit {

    loginForm: FormGroup;
    forgotPasswordForm: FormGroup;
    loginFormErrors: any;
    forgotPasswordFormErrors: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(public router: Router,
                public dialog: MatDialog,
                public service: UserService,
                public loading: NgxSpinnerService,
                private _fuseConfigService: FuseConfigService,
                private _formBuilder: FormBuilder) {
        // Configure the layout
        super(router, dialog, loading);
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });

        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.onForgotPasswordFormValuesChanged();
        });
    }

    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }


    onForgotPasswordFormValuesChanged(): void {
        for (const field in this.forgotPasswordFormErrors) {
            if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.forgotPasswordFormErrors[field] = {};

            // Get the control
            const control = this.forgotPasswordForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.forgotPasswordFormErrors[field] = control.errors;
            }
        }
    }


    // onLogin(email: string, password: string) {
    onLogin(): void {
        const email = this.loginForm.value.email;
        const password = this.loginForm.value.password;


        if (!this.loginForm.valid) {
            return;
        }

        this.loading.show();

        this.service.login(email, password).subscribe(
            data => {
                // const result = data as ResponseServer;
                const user = data as User;
                localStorage.setItem('token', data['token']);
                super.setUser(user);
                this.router.navigate(['/dashboard']);

                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

}
