export class PropositionForm {
    id: string;
    policy_number: string;
    created_at: string;
    type: string;
    validity_start: string;
    validity_end: string;
    validity_policy_end: string;
    validity_endorsement_end: string;
    vehicle: string;
    plate: string;
    chassi: string;
    name: string;
    observation: string;
    cpf_cnpj: string;
    date: string;
    proposition_number: string;
    marital: string;
    birth: string;
    gender: string;
    email: string;
    address: string;
    number: string;
    neighborhood: string;
    zip_code: string;
    complement: string;
    city: string;
    state: string;
    phone: string;
    mapfre_code: string;
    fipe_code: string;
    brand: string;
    model: string;
    year_model: string;
    year_factory: string;
    adjustment_factor: string;
    driver_name: string;
    driver_birth: string;
    driver_cpf_cnpj: string;
    driver_marital: string;
    driver_gender: string;
    driver_city: string;
    driver_state: string;
    driver_zip_code: string;
    driver_under_26_people: string;
    driver_license_time: string;
    driver_under_26_child: string;
    vehicle_use: string;
    vehicle_park: string;
    casco: string;
    casco_premium: string;
    material_damage: string;
    material_damage_premium: string;
    body_damage: string;
    body_damage_premium: string;
    moral_damage: string;
    moral_damage_premium: string;
    liquid_premium: string;
    iof: string;
    total_premium: string;
    first_quota: string;
    income: string;
    reference_table: string = 'FIPE';
    adjustment: string;
    endorsement: string;
    person_type: string;
    broker: string;

    constructor() {

    }
}
